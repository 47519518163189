// @import '~antd/lib/style/themes/dark.less';
@import '~antd/dist/antd.less';

#root {
  position: relative;
  fieldset:disabled {
    .ant-input {
      border: none;
      resize: none;
      &::placeholder {
        visibility: hidden;
      }
    }
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  // ANTD
  .ant-card {
    margin-bottom: 1rem;
    box-shadow: @shadow-1-down;
  }
  .ant-layout-header {
    box-shadow: @shadow-1-down;
  }
  .ant-layout-content {
    margin-top: 81px;
    min-height: calc(100vh - 102px);
    padding: 0 1rem;
  }
  .ant-layout-footer {
    padding: 0 1rem;
    background: transparent;
  }
  .ant-table {
    .ant-table-content {
      overflow-y: auto;
      thead {
        tr {
          th {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td {
            a.tel {
              color: @text-color;
            }
          }
        }
      }
    }
  }
}

@primary-color: #60448E;